/*
 * Warning! The following functions can't be used
 * inside .render() method. They rely on the fact that
 * window and all its properties are defined.
 */

import { AccountBrandInfoResponse } from '@bloom/codegen/models/AccountBrandInfoResponse';
import { AccountResponse } from '@bloom/codegen/models/AccountResponse';
import { PublicAccountResponse } from '@bloom/codegen/models/PublicAccountResponse';

export function throttleHandler(handler: (e: unknown) => void) {
  let isRunning = false;

  return (e: unknown) => {
    if (isRunning) {
      return;
    }

    if (window.requestAnimationFrame) {
      window.requestAnimationFrame(() => {
        handler(e);
        isRunning = false;
      });
    } else {
      setTimeout(() => {
        handler(e);
        isRunning = false;
      }, 66);
    }
    isRunning = true;
  };
}

/**
 * Will race between 2 consecutive `mousemove` events and resolve or 2 consecutive `mousedown` events and reject
 * @see https://github.com/stucox/Modernizr/blob/hover/feature-detects/device/hover.js
 */
export const isHoverCapable = () =>
  new Promise((resolve, reject) => {
    let hasMouseMovedBeforeClick: boolean;

    function onMouseDown() {
      if (hasMouseMovedBeforeClick === false) {
        finishWith(reject); // eslint-disable-line
      }
      hasMouseMovedBeforeClick = false;
    }

    function onMouseMove() {
      if (hasMouseMovedBeforeClick) {
        finishWith(resolve); // eslint-disable-line
      }
      hasMouseMovedBeforeClick = true;
    }

    function finishWith(fn: (value?: unknown) => void) {
      document.removeEventListener('mousedown', onMouseDown);
      document.removeEventListener('mousemove', onMouseMove);
      fn();
    }

    document.addEventListener('mousedown', onMouseDown);
    document.addEventListener('mousemove', onMouseMove);
  });

const UA = typeof navigator === 'undefined' ? 'SSR' : navigator.userAgent;

export function detectDeviceType(userAgent = UA): {
  isAndroid: boolean;
  isChromeIos: boolean;
  isDesktop: boolean;
  isIos: boolean;
  isMobile: boolean;
  isSSR: boolean;
  isSafari: boolean;
  isTouchScreen: boolean;
} {
  const isOperaMini = Boolean(userAgent.match(/Opera Mini/i));
  const isIEMobile = Boolean(userAgent.match(/IEMobile/i));
  const isSSR = Boolean(userAgent.match(/SSR/i));
  const isAndroid = Boolean(userAgent.match(/Android/i));
  const isChromeIos = Boolean(userAgent.match(/CriOS/i));
  const isIos = Boolean(userAgent.match(/iPhone|iPad|iPod/i));

  const isMobile = Boolean(
    isAndroid ||
      isIos ||
      isOperaMini ||
      isIEMobile ||
      (typeof window !== 'undefined' && window.innerWidth < 768)
  );
  const isDesktop = Boolean(!isMobile && isSSR);

  const isTouchScreen = Boolean(
    typeof window !== 'undefined' && (navigator.maxTouchPoints || 'ontouchstart' in window)
  );

  function isSafariFn() {
    // https://stackoverflow.com/a/7944490
    if (userAgent.match(/safari/gi)) {
      return userAgent.match(/chrome/gi) ? false : true;
    }
    return false;
  }

  return {
    isAndroid,
    isChromeIos,
    isDesktop,
    isIos,
    isMobile,
    isSafari: isSafariFn(),
    isSSR,
    isTouchScreen,
  };
}

export function isSafari() {
  return detectDeviceType().isSafari;
}

export function isTouchScreen() {
  return detectDeviceType().isTouchScreen;
}

export function isMobile() {
  return detectDeviceType().isMobile;
}

export function isChromeIos() {
  return detectDeviceType().isChromeIos;
}

export function isIOS() {
  return detectDeviceType().isIos;
}

export function getUserHostname(domain: string, subdomain: string) {
  if (domain) {
    return domain;
  }

  return `${subdomain || process.env.BLOOM_DEMO_SUBDOMAIN}.${process.env.BLOOM_DOMAIN}`;
}

export function getHostnameFromAccount(
  account: PublicAccountResponse | AccountBrandInfoResponse | AccountResponse | null | undefined
) {
  if (process.env.APP_ENV === 'test') {
    console.log('🚨 ~ account:', account);
  }
  if (account?.settings) {
    const hostname = getUserHostname(account.settings.domain, account.settings.customUrl);
    if (process.env.APP_ENV === 'test') {
      console.log('🚨 ~ hostname:', hostname);
    }
    return hostname;
  }

  const hostname = getUserHostname('', '');
  if (process.env.APP_ENV === 'test') {
    console.log('🚨 ~ hostname:', hostname);
  }
  return hostname;
}

export function getLocationOriginFromAccount(
  account: PublicAccountResponse | AccountBrandInfoResponse | AccountResponse | null | undefined
) {
  return `https://${getHostnameFromAccount(account)}`;
}

export function getFreelancerSubdomainFromHost() {
  if (process.env.BLOOM_CUSTOM_URL_OVERRIDE) {
    return process.env.BLOOM_CUSTOM_URL_OVERRIDE;
  }

  if (typeof window === 'undefined') {
    // During SSR
    return '';
  }

  const { host } = window.location;

  if (!host.includes('.bloom.io')) {
    return host;
  }

  return host.split('.')[0] || '';
}

/*
 * Note, this function sets cookie to picr.com or bloom.io domain,
 * so it is available across all subdomains.
 *
 * @param expires String a string representing some future date or empty string.
 */
export function setCookie(name: string, value: string, expires?: string) {
  let expiresAt;
  // By default set to expire in one month.
  if (expires === undefined) {
    const date = new Date();
    date.setMonth(date.getMonth() + 1);
    expiresAt = `expires=${date.toUTCString()};`;
  } else if (expires === 'session') {
    expiresAt = '';
  } else {
    expiresAt = `expires=${expires};`;
  }

  const { hostname } = window.location;
  // Strip www prefix to make it work on both www.doamin.com and domain.com.
  let domain = hostname.replace(/^www/, '');
  if (hostname.includes('.picr.com')) {
    domain = '.picr.com';
  } else if (hostname.includes('.bloom.io')) {
    domain = '.bloom.io';
  }

  document.cookie = `${name}=${value}; domain=${domain}; path=/; ${expiresAt}`;
}

export function getCookie(cookieName: string, cookieString?: string) {
  const cookies = cookieString ?? (typeof document === 'undefined' ? '' : document.cookie);

  const pattern = `${cookieName}=([^;]+)(?:;|$)`;
  // convert Document.cookie to string
  // Despite typeof document.cookie is a string
  // in vitest env it does not have 'match` method
  const [, cookieValue = ''] = cookies.match(new RegExp(pattern)) || [];

  return cookieValue || '';
}

/*
 * Invalidates cookie by setting expires to last month.
 */
export function deleteCookie(name: string) {
  const date = new Date();
  date.setMonth(date.getMonth() - 1);
  setCookie(name, '', date.toUTCString());
}

export function isHighDensityDisplay() {
  return (
    typeof window !== 'undefined' &&
    ((window.matchMedia &&
      (window.matchMedia(
        'only screen and (min-resolution: 124dpi), only screen and (min-resolution: 1.3dppx), only screen and (min-resolution: 48.8dpcm)'
      ).matches ||
        window.matchMedia(
          'only screen and (-webkit-min-device-pixel-ratio: 1.3), only screen and (-o-min-device-pixel-ratio: 2.6/2), only screen and (min--moz-device-pixel-ratio: 1.3), only screen and (min-device-pixel-ratio: 1.3)'
        ).matches)) ||
      (window.devicePixelRatio && window.devicePixelRatio > 1.3))
  );
}

/**
 * A wrapper for window.open() with preset arguments.
 * @param {string} url URL of the page to open in a new window.
 * @returns {WindowProxy} An object representing the newly created window.
 */
export function openWindow(url: string) {
  return window.open(url, '_blank', 'location=yes,height=570,width=520,scrollbars=yes,status=yes');
}

export function isInternationalUser() {
  return (
    typeof window !== 'undefined' &&
    window.navigator &&
    window.navigator.language &&
    window.navigator.language !== 'en-US'
  );
}
