'use client';
import { useCallback, useMemo, useState } from 'react';

import { useMounted } from '@bloom/ui/components/hooks/useMounted';

export function useSafeState<T>(initState: T): [T, (newState: T) => void] {
  const isMountedRef = useMounted();

  const [state, setState] = useState<T>(initState);

  const handleSetState = useCallback(
    (newState: T) => {
      if (isMountedRef.current) {
        setState(newState);
      }
    },
    [isMountedRef]
  );

  return useMemo(() => [state, handleSetState], [handleSetState, state]);
}
