// use this enum for shared react query hooks
export enum SharedQueryKeyEnum {
  ACCOUNT = 'account',
  ACTIVE_TEMPLATE = 'active-template',
  ACCOUNTS_LIST = 'accounts-list',
  AGGREGATED_AVAILABILITY_EXCEPTIONS = 'aggregated-availability-exceptions',
  ANSWER_GROUP = 'answer-group',
  AVAILABILITY_SETTINGS_PUBLIC = 'availability-settings-public',
  BOOKING_PACKAGES = 'booking-packages',
  EVENT_MESSAGE = 'event-message',
  FREELANCER_BRAND_COLOR = 'freelancer-brand-color',
  FREELANCER_PUBLIC_PROFILE = 'freelancer-public-profile',
  GALLERY_IMAGE_LIKES_LIST = 'gallery-image-likes-list',
  GOOGLE_FONTS = 'google-fonts',
  INBOUND_MESSAGE = 'inbound-message',
  LOADER_PUBLIC_ACCOUNT = 'loader-public-account',
  PUBLIC_ACCOUNT = 'public-account',
  PUBLIC_CUSTOM_FONTS = 'public-custom-fonts',
  PUBLIC_GALLERY = 'public-gallery',
  PUBLIC_QUESTIONNAIRE = 'public-questionnaire',
  TIMEZONES_LIST = 'timezones-list',
  USER_ME = 'user-me',
  USER_PROFILE = 'user-profile',
  WIDGET_VERSION = 'widget-version',
}
