'use client';
import { useCallback, useMemo } from 'react';

import { useQueryClient } from '@tanstack/react-query';
import merge from 'deepmerge';
import fileSaver from 'file-saver';

import { UserResponse } from '@bloom/codegen/models/UserResponse';

import { removeUndefined } from '@bloom/ui/utils/object';

import { useSafeState } from '@bloom/library/components/hooks/useSafeState';
import { TResponse } from '@bloom/library/types/general';
import { getCookie } from '@bloom/library/utils/browser';

import { SharedQueryKeyEnum } from './interface';

const { saveAs } = fileSaver;

export enum AsyncStatusEnum {
  ABORT = 'abort',
  ERROR = 'error',
  IDLE = 'idle',
  PENDING = 'pending',
  START = 'start',
  SUCCESS = 'done',
}

export interface IPendingAsyncAction {
  status: AsyncStatusEnum.START;
  type: string;
}

export interface ISuccessAsyncAction<T> {
  data: T;
  status: AsyncStatusEnum.SUCCESS;
  type?: string;
}

export interface IResponseError {
  clientMessages?: Array<{ key: string; messages: Array<string | Record<string, string[]>> }>;
  debug?: { message: string };
  message: string;
  status: number;
}

export interface IErrorAsyncAction {
  error: IResponseError;
  status: AsyncStatusEnum.ERROR;
  type?: string;
}

export const NotAllowedResponse: IErrorAsyncAction = {
  error: { message: 'You are not allowed to perform this action', status: 403 },
  status: AsyncStatusEnum.ERROR,
};

export const BadRequestResponse: IErrorAsyncAction = {
  error: { message: 'Bad Request', status: 400 },
  status: AsyncStatusEnum.ERROR,
};

export function getFetchConfig(isBloomApi = true): RequestInit {
  const authToken = getCookie('bloom_token');

  return {
    headers: {
      Accept: isBloomApi ? 'application/vnd.bloom.v3' : 'application/json',
      'Content-Type': 'application/json',
      ...(authToken && { Authorization: `bearer ${authToken}` }),
    },
    method: 'GET',
    mode: 'cors',
  };
}

export async function bloomFetch<T>(
  url: string,
  fetchOptions: RequestInit = {}
): Promise<TResponse<T>> {
  const fetchConfig: RequestInit = removeUndefined(merge(getFetchConfig(), { ...fetchOptions }));

  Object.entries(fetchOptions.headers || {}).forEach(([key, value]) => {
    if (value === undefined && fetchConfig.headers && key in fetchConfig.headers) {
      delete fetchOptions.headers[key];
    }
  });

  if (fetchOptions?.headers === null) {
    delete fetchConfig.headers;
  }

  try {
    const [urlWithoutQuery = ''] = url.split('?');
    const isBloomEndpoint =
      !urlWithoutQuery.startsWith('http') && !urlWithoutQuery.includes('bloom.io');

    const requestUrl = isBloomEndpoint ? `${process.env.BLOOM_API}${url}` : url;
    const response = await fetch(requestUrl, fetchConfig);

    if (response.ok) {
      const json = fetchConfig.method !== 'DELETE' ? ((await response.json()) as T) : undefined;
      return {
        data: json,
        status: AsyncStatusEnum.SUCCESS as const,
      };
    }

    const json = (await response.json()) as IResponseError;

    return {
      error: json,
      status: AsyncStatusEnum.ERROR,
    } satisfies IErrorAsyncAction;
  } catch (e) {
    return {
      error: e as IResponseError,
      status: AsyncStatusEnum.ERROR as const,
    } as TResponse<T>;
  }
}

export function useFetch(): {
  download: (url: string, fileName?: string) => Promise<TResponse<never>>;
  get: <TRes>(url: string, fetchOptions?: RequestInit) => Promise<TResponse<TRes>>;
  // delete is a reserved word
  handleDelete: (url: string) => Promise<TResponse<never>>;
  status: AsyncStatusEnum;
} & Record<
  'patch' | 'post' | 'put',
  <TRes, TReq>(url: string, request: TReq, fetchOptions?: RequestInit) => Promise<TResponse<TRes>>
> {
  const queryClient = useQueryClient();

  const me = queryClient.getQueryData<UserResponse>([SharedQueryKeyEnum.USER_ME]);
  const [status, setStatus] = useSafeState<AsyncStatusEnum>(AsyncStatusEnum.IDLE);

  const handleDownload = useCallback(
    async (url: string, fileName = '') => {
      setStatus(AsyncStatusEnum.PENDING);

      try {
        const requestUrl = url.startsWith('http') ? url : `${process.env.BLOOM_API}${url}`;

        const fetchConfig = { ...getFetchConfig(requestUrl.startsWith(process.env.BLOOM_API)) };

        const response = await fetch(
          requestUrl,
          merge(fetchConfig, {
            headers: { ...(url.startsWith('http') && { Accept: 'application/json' }) },
          })
        );

        if (response.ok) {
          const contentType = response.headers.get('Content-Type') ?? '';

          if (contentType.indexOf('text/csv') > -1) {
            const blob = await response.blob();
            saveAs(blob, fileName);
          }

          if (contentType.indexOf('application/pdf') > -1) {
            const contentDisposition = response.headers.get('Content-Disposition') || '';
            const filenameRegex = /filename[^;=\n]*=((['"]).*?\2|[^;\n]*)/;
            // Notice leading comma. Need to match with index 1
            const [, filename] = contentDisposition.match(filenameRegex) || [];

            response.blob().then((blob) => saveAs(blob, filename));
          }

          setStatus(AsyncStatusEnum.SUCCESS);

          return {
            data: undefined,
            status: AsyncStatusEnum.SUCCESS as const,
          };
        }

        setStatus(AsyncStatusEnum.ERROR);

        const json = (await response.json()) as IResponseError;

        return {
          error: json,
          status: AsyncStatusEnum.ERROR,
        } satisfies IErrorAsyncAction;
      } catch (e) {
        setStatus(AsyncStatusEnum.ERROR);

        return {
          error: e as IResponseError,
          status: AsyncStatusEnum.ERROR as const,
        } as TResponse<unknown>;
      }
    },
    [setStatus]
  );

  const handleFetch = useCallback(
    async <T,>(url: string, fetchConfigProp: Partial<RequestInit> = {}) => {
      setStatus(AsyncStatusEnum.PENDING);

      // cutting of query params because in E2E test we are using @bloom.io email's for fake accounts
      // We also have a few endpoints where we send the email in the url query params
      // So url.includes('bloom.io') returns false positive value
      const [urlWithoutQuery = ''] = url.split('?');
      const isBloomEndpoint =
        !urlWithoutQuery.startsWith('http') && !urlWithoutQuery.includes('bloom.io');

      const fetchConfig: RequestInit = removeUndefined(
        merge(getFetchConfig(isBloomEndpoint), {
          ...(isBloomEndpoint &&
            me?.defaultAccountId && { headers: { 'x-account': me.defaultAccountId } }),
          ...fetchConfigProp,
        })
      );

      const result = await bloomFetch<T>(url, fetchConfig);
      setStatus(result.status);
      return result;
    },
    [me?.defaultAccountId, setStatus]
  );

  const handleDelete = useCallback(
    (url: string) => handleFetch<never>(url, { method: 'DELETE' }),
    [handleFetch]
  );

  const handleGet = useCallback(
    <TRes,>(url: string, fetchOptions?: RequestInit) =>
      handleFetch<TRes>(url, { method: 'GET', ...fetchOptions }),
    [handleFetch]
  );

  const handlePatch = useCallback(
    <TRes, TReq>(url: string, request: TReq, fetchOptions?: RequestInit) =>
      handleFetch<TRes>(url, { body: JSON.stringify(request), method: 'PATCH', ...fetchOptions }),
    [handleFetch]
  );

  const handlePut = useCallback(
    <TRes, TReq>(url: string, request: TReq, fetchOptions?: RequestInit) =>
      handleFetch<TRes>(url, { body: JSON.stringify(request), method: 'PUT', ...fetchOptions }),
    [handleFetch]
  );

  const handlePost = useCallback(
    <TRes, TReq>(url: string, request: TReq, fetchOptions?: RequestInit) => {
      return handleFetch<TRes>(url, {
        body: JSON.stringify(request),
        method: 'POST',
        ...fetchOptions,
      });
    },
    [handleFetch]
  );

  return useMemo(
    () => ({
      download: handleDownload,
      get: handleGet,
      handleDelete: handleDelete,
      patch: handlePatch,
      post: handlePost,
      put: handlePut,
      status,
    }),
    [handleDelete, handleDownload, handleGet, handlePatch, handlePost, handlePut, status]
  );
}
