import { useEffect, useRef } from 'react';

export function useMounted(initState?: boolean): React.MutableRefObject<boolean> {
  const isMountedRef = useRef(initState || false);

  useEffect(() => {
    isMountedRef.current = true;
    return () => {
      isMountedRef.current = false;
    };
  }, []);

  return isMountedRef;
}
